// Generic Re-Usabel Accordian

import React from 'react';
import { Accordion as MuiAccordion, Box } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { TypographyProps } from '@mui/material/Typography';

interface AccordianProps {
  children: React.ReactNode;
  title: string;
  expanded?: boolean;
  variant?: TypographyProps['variant'];
}

const Accordian = ({ title, children, expanded, variant }: AccordianProps) => {
  return (
    <MuiAccordion
      component={Box} // present as a div
      defaultExpanded={expanded}
      disableGutters
      elevation={0} // Remove paper shadow
      sx={{
        backgroundColor: 'transparent', // Ensure transparent background
        '&:before': {
          display: 'none', // Remove default accordion divider line and gradient
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography
          // variant="h4" is default
          variant={variant ? variant : 'h4'}
          component={'div'} // present as a div
          sx={{ my: '.47rem' }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordian;
