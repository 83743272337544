/******************************************************************************************
 * * COMPONENT SHIPPING
 * ---------------------------------------------------------------------------------------
 * This component displays the shipping information for the current user and allows them
 * to update their shipping information.
 * ***************************************************************************************/

import React, { ChangeEvent, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Grid2'
import { updateShippingAddress } from '../../data/updateShippingAddress';

/******************************************************************************************
 * * IMPORT ACCOUNT STORE
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/
import { useAccountStore } from '../../store';

/******************************************************************************************
 * * IMPORT TYPES
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/
import { USStates } from '../../types';
import { set } from 'lodash';

export const Shipping = () => {
  /******************************************************************************************
   * * SUBSCRIBE TO DATA IN THE ELO ACCOUNT STORE
   * ---------------------------------------------------------------------------------------
   * Only subscribe to the data you need. In this case, the firstName and lastName from
   * the userAccount, and the shippingAddress which is a nested object within the
   * userAccount.
   * ***************************************************************************************/
  // * State
  const firstName = useAccountStore(state => state.userAccount?.firstName);
  const lastName = useAccountStore(state => state.userAccount?.lastName);
  const shippingAddress = useAccountStore(state => state.userAccount?.shippingAddress);
  const [loading, setLoading] = useState(false);

  // * Actions
  const updateAccountStore = useAccountStore(state => state.updateAccountState);

  /******************************************************************************************
   * * HANDLE UPDATE ACCOUNT SPECIFIC DATA
   * ---------------------------------------------------------------------------------------
   * ***************************************************************************************/
  const handleAccountUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name ?? undefined;
    const value = e.target.value ?? '';

    updateAccountStore(state => {
      if (state.userAccount) {
        // Assert that userAccount is not null and has an index signature
        (state.userAccount as { [key: string]: any })[name] = value;
      }
    });
  };

  /******************************************************************************************
   * * HANDLE UPDATE SHIPPING ADDRESS DATA
   * ---------------------------------------------------------------------------------------
   * ***************************************************************************************/
  // const handleAccountShippingAddress = (e: React.ChangeEvent<HTMLInputElement>  | SelectChangeEvent ) => {
  const handleAccountShippingAddress = (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
    const name = e.target.name ?? undefined;
    const value = e.target.value ?? '';

    updateAccountStore(state => {
      if (state.userAccount?.shippingAddress) {
        // Assert that userAccount is not null and has an index signature
        (state.userAccount.shippingAddress as { [key: string]: any })[name] = value;
      }
    });
  };

  /******************************************************************************************
   * * HANDLE UPDATE SHIPPING ADDRESS
   * ---------------------------------------------------------------------------------------
   * ***************************************************************************************/
  const handleUpdateShippingAddress = async () => {
    setLoading(true);
    const result = await updateShippingAddress();
    setLoading(false);
  };

  return (
    <>
      {/* <Box>
        <Divider sx={{ my: '1.47rem' }} />
        <Typography variant="h3">Shipping Information</Typography>
      </Box> */}
      <Box>
        <Grid container spacing="18px">
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              id="firstName"
              name="firstName"
              label="First Name"
              value={firstName}
              variant="outlined"
              required
              fullWidth
              size="medium"
              onChange={handleAccountUpdate}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              id="lastName"
              name="lastName"
              label="Last Name"
              value={lastName}
              variant="outlined"
              required
              fullWidth
              size="medium"
              onChange={handleAccountUpdate}
            />
          </Grid>
          {/* <Grid size={{ xs: 12 }}>
            <TextField
              id="company"
              name="company"
              label="Company (Optional)"
              value={shippingAddress?.company}
              variant="outlined"
              fullWidth
              size="medium"
              onChange={handleAccountShippingAddress as React.ChangeEventHandler<HTMLInputElement>} // Cast as necessary
            />
          </Grid> */}
          <Grid size={{ xs: 12 }}>
            <TextField
              id="street"
              name="street"
              label="Street Address"
              value={shippingAddress?.street}
              variant="outlined"
              fullWidth
              size="medium"
              onChange={handleAccountShippingAddress as React.ChangeEventHandler<HTMLInputElement>} // Cast as necessary
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <TextField
              id="street2"
              name="street2"
              label="Address Line 2 (Optional)"
              value={shippingAddress?.street2}
              variant="outlined"
              fullWidth
              size="medium"
              onChange={handleAccountShippingAddress as React.ChangeEventHandler<HTMLInputElement>} // Cast as necessary
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <TextField
              id="city"
              name="city"
              label="City"
              value={shippingAddress?.city}
              variant="outlined"
              fullWidth
              size="medium"
              onChange={handleAccountShippingAddress as React.ChangeEventHandler<HTMLInputElement>} // Cast as necessary
            />
          </Grid>          
          <Grid size={{ xs: 12, sm:6 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="state-select-label" size="small">
                State
              </InputLabel>
              <Select
                labelId="state-select-label"
                id="state"
                name="state"
                value={shippingAddress?.state ?? ''}
                onChange={handleAccountShippingAddress}
                label="State"
                size="medium" // Ensure this is set to "medium" for the Select component
              >
                {Object.entries(USStates).map(([code, name]) => (
                  <MenuItem key={code} value={code}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm:6 }}>
            <TextField
              id="zip"
              label="Zip"
              name="zip"
              value={shippingAddress?.zip}
              variant="outlined"
              fullWidth
              size="medium"
              onChange={handleAccountShippingAddress as React.ChangeEventHandler<HTMLInputElement>} // Cast as necessary
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              sx={{
                px: ['1.47rem'],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1, // Adjust the gap as needed for visual appearance
              }}
              onClick={handleUpdateShippingAddress}
              disabled={loading}
            >
              {loading ? (
                <>
                  <CircularProgress
                    size={24}
                    color="inherit" // This makes the progress indicator use the current text color
                  />
                  <Typography variant="body2">Updating...</Typography>
                </>
              ) : (
                <Typography variant="body2">Update</Typography>
              )}
            </Button>            
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Shipping;
