import React from 'react';
import { Box, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ChangePassword, DeleteUser, UserInformation, Shipping } from '../../../../ui';
import { Accordian } from '../../components';
import { useAccountStore } from '../../../../store';

const Account = () => {
  const userAccount = useAccountStore(state => state.userAccount);

  return (
    <>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Divider />
          <Box>
            <UserInformation />
          </Box>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Accordian title="Shipping Address" expanded={false}>
            <Shipping />
          </Accordian>
        </Grid>

        {/* <Grid xs={12}>
          <Accordian title="Payment Method">
            <Payment />
          </Accordian>
        </Grid> */}

        <Grid size={{ xs: 12 }}>
          <Divider />
          <Box>
            <ChangePassword />
          </Box>
        </Grid>

        {!userAccount?.deleteScheduledAfter && (
          <Grid size={{ xs: 12 }}>
            <Divider />
            <Box>
              <DeleteUser />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Account;
