/******************************************************************************************
 * * SUBSCRIPTIONS
 * ---------------------------------------------------------------------------------------
 * The subscriptions component displays the current subscriptions for the user and allows
 * them to update their subscriptions. It will likely consist of several different
 * components that are used to display the different types of subscriptions the user has.
 * ***************************************************************************************/

import React from 'react';
import { pregnancyStage } from '../../elo.config';
import { Box, Typography, Divider, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2'
import { formatGummyFlavor, formatLabel, formatDate, dateMinusDays } from '../../utils';
import { GummyFlavor } from '../../types/API';
import { formatCurrency } from '../../utils';
import { ProductImage, SubscriptionStatus } from './components';
import { Accordian } from '../Components';
import CircleIcon from '@mui/icons-material/Circle';

/******************************************************************************************
 * * IMPORT ACCOUNT STORE
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/
import { useAccountStore } from '../../store';

/******************************************************************************************
 * * IMPORT TYPES
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/
import { UserSubscription } from '../../types/API';
import { sub } from 'date-fns';

export const UserSubscriptions = () => {
  /******************************************************************************************
   * * SUBSCRIBE TO DATA IN THE ELO ACCOUNT STORE
   * ---------------------------------------------------------------------------------------
   * Only subscribe to the data you need. In this case, the subscriptions from the userAccount.
   * ***************************************************************************************/
  // * State
  const userAccount = useAccountStore(state => state.userAccount);
  const subscriptions = useAccountStore(state => state.userAccount?.subscriptions);

  if (!subscriptions) return null;

  let items = [...subscriptions.items];
  /******************************************************************************************
   * * PENDING SUBSCRIPTIONS
   * ---------------------------------------------------------------------------------------
   * When a user first signs migrates from preconcpetion to pregnancy, there will be a
   * delay before the actual subscription items are returned. This is because it takes a
   * time for the user's account to be updated in the ELO cloud. There for, we need to
   * display some kind of placeholder until the actual subscription items are returned.
   *
   * The indicator is
   * Pregnancy Stage is not preconception && subscriptions.items.length === 1
   * ***************************************************************************************/

  const stage = userAccount?.preferences?.pregnancyStage;
  const itemNumber = subscriptions.items.length;

  const getStatus = (subscription: UserSubscription) => {
    if (subscription.cancellationRequestedAt) return 'Cancellation Pending';
    return formatLabel(subscription.status);
  };

  const getStatusColor = (subscription: UserSubscription) => {
    if (subscription.cancellationRequestedAt) return '#f69b32';
    return 'green';
  };

  /******************************************************************************************
   * * GET STAGE
   * ---------------------------------------------------------------------------------------
   * Get the stage of the subscription. This is used to display the stage of the pregnancy
   * the user is in. It is based on the product stage and phase.
   * ***************************************************************************************/
  const getStage = (subscription: UserSubscription) => {
    
    let slug;
    if (subscription.purchasedSubscription?.phases) {
      slug = subscription.purchasedSubscription?.phases[0]?.slug;
    } else {
      slug = subscription.purchasedSubscription?.slug;
    }
    
    let stage;
    switch (slug) {
      case 'create':
        stage = 'Preconception';
        break;
      case 'form':
        stage = 'Early pregancy';
        break;
      case 'flourish':
        stage = 'Late pregnancy';
        break;
      case 'nurture':
        stage = 'Postpartum';
        break;
      default:
        stage = '';
        break;
    }
    return stage;
  };

  /******************************************************************************************
   * * GET PHASE
   * ---------------------------------------------------------------------------------------
   * Get the phase of the subscription. This is used to display the correct state image for
   * the subscription. It is based on the product stage and phase.
   * ***************************************************************************************/
  const getPhase = (subscription: UserSubscription) => {
    let phase;
    if (subscription.purchasedSubscription?.phases) {
      phase = subscription.purchasedSubscription?.phases[0]?.slug;
    } else {
      phase = subscription.purchasedSubscription?.slug;
    }
    return phase;
  }

  /******************************************************************************************
   * * GET TITLE
   * ---------------------------------------------------------------------------------------
   * The title has some complex logic to determine the correct title to display. It is based
   * the product as well as the phase of a specific product. The title returned by the API
   * only returns the product title but the title that we display to the user needs to also
   * reflect the phase of the product. Therefore, we need to determine the correct title to
   * display based on the product and phase.
   * ***************************************************************************************/
  const getTitle = (subscription: UserSubscription) => {
    
    let title = 'create';

    if (subscription.purchasedSubscription?.phases) {
      title = subscription.purchasedSubscription?.phases[0]?.slug;
      switch (title) {        
        case 'create':
          title = 'Luminary Vitamins Create';
          break;
        case 'form':
          title = 'Luminary Vitamins Form';
          break;
        case 'flourish':
          title = 'Luminary Vitamins Flourish';
          break;
        case 'nurture':
          title = 'Luminary Vitamins Nurture';
          break;
        default:
          title = '';
          break;
      
      }
    }
    return title;
  }

  return (
    <>
      <Stack spacing={4}>
        {items.map((subscription, index) => {
          // * RETURN NULL IF THERE IS NO SUBSCRIPTION
          if (!subscription) return null;

          return (
            <>
              <Grid
                container
                spacing={2}
                sx={{
                  background: '#F8F9F2',
                  borderRadius: '8px',
                  border: '2px solid #E5E5E5',
                }}
                key={index}
              >
                <Grid size={{ xs: 12 }}>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    flexWrap={'wrap'}
                    my={['1rem']}
                    px={['.47rem']}
                  >                    
                    <Typography variant="h4">{getTitle(subscription)}</Typography>
                    <Box display={'flex'} alignItems={'center'}>
                      <Typography variant="body2">{getStatus(subscription)}</Typography>
                      <Box px={['.47rem']}>
                        <CircleIcon
                          sx={{
                            color: getStatusColor(subscription),
                            fontSize: 'small',
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={{ mt: ['1.4rem'] }} />
                </Grid>

                <Grid size={{ xs: 6 }}>
                  <Box
                    sx={{
                      maxHeight: '240px',
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ProductImage
                      id={subscription.purchasableSubscriptionId}
                      alt={subscription.title ?? 'Product Image'}                    
                      slug={getPhase(subscription)}
                    />
                  </Box>
                </Grid>

                <Grid size={{ xs: 6 }}>
                  <Box py={['.47rem']}>
                    <Typography variant="subtitle2">Stage</Typography>
                    <Typography variant="body2">                      
                      {getStage(subscription)}
                    </Typography>
                  </Box>

                  <Box py={['.47rem']}>
                    <Typography variant="subtitle2">Flavor</Typography>
                    <Typography variant="body2">
                      {formatGummyFlavor(userAccount?.preferences.gummyFlavor as GummyFlavor)}
                    </Typography>
                  </Box>

                  <Box py={['.47rem']}>
                    <Typography variant="subtitle2">Price</Typography>
                    <Typography variant="body2">
                      {formatCurrency(subscription.invoices?.items[0]?.total ?? (69 as number))} /
                      mo
                    </Typography>
                  </Box>

                  {/**********************************************************************
                   * * NEXT and FINAL BILL DATE
                   * ---------------------------------------------------------------------
                   * Next Bill Date if the subscription is active.
                   * Last Bill Date if the subscription is canceled.
                   **********************************************************************/}
                  {subscription.cancellationRequestedAt ? (
                    <>
                      <Box py={['.47rem']}>
                        <Typography variant="subtitle2">Final bill date</Typography>
                        <Typography variant="body2">
                          {!subscription.upcomingInvoices
                            ? ''
                            : formatDate(dateMinusDays(subscription.committedUntil as string, 30),'MMM dd, yyyy')}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box py={['.47rem']}>
                        {subscription.upcomingInvoices && (
                          <>
                            <Typography variant="subtitle2">Next bill date</Typography>
                            <Typography variant="body2">
                              {!subscription.upcomingInvoices
                                ? 'Cancelled'
                                : formatDate(
                                    subscription.upcomingInvoices?.[0]?.dueAt ?? '',
                                    'MMM dd, yyyy'
                                  )}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </>
                  )}
                </Grid>

                {/**********************************************************************
                 * * MANAGE SUBSCRIPTION
                 * ---------------------------------------------------------------------
                 * This section allows the user to manage their subscription. They can
                 * pause, cancel, or update their subscription.
                 * **********************************************************************/}

                {subscription.cancellationRequestedAt ? (                  
                  <> </>
                ) : (
                  <Grid size={{ xs: 12 }}>
                    <Accordian title="Manage Subscription" expanded={false} variant="body2">                      
                      <Box pb={['.47rem']}>
                        <SubscriptionStatus subscription={subscription} />
                      </Box>
                    </Accordian>
                  </Grid>
                )}
              </Grid>
            </>
          );
        })}
      </Stack>
    </>
  );
};

export default UserSubscriptions;
