interface GetColorProps {
  rgba: {
    _type: string;
    r: number;
    g: number;
    b: number;
    a: number;
  };
}

export const getCSSColor = ({ rgba }: GetColorProps): string | null => {
  if (rgba) {
    return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
  } else {
    return null
  }
};

export default getCSSColor;
