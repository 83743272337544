/******************************************************************************************
 * * USER INFORMATION COMPONENT
 * ---------------------------------------------------------------------------------------
 * This component displays the user information for the current user and allows them
 * to update their user information.
 * ***************************************************************************************/

import React, { useState } from 'react';
import { Accordian } from '../Components';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2'
import { updateUserName } from '../../data';

/******************************************************************************************
 * * IMPORT ACCOUNT STORE
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/
import { useAccountStore } from '../../store';

const UserInformation = () => {
  const [loading, setLoading] = useState(false);

  /******************************************************************************************
   * * SUBSCRIBE TO ELO ACCOUNT STORE DATA
   * ---------------------------------------------------------------------------------------
   * ***************************************************************************************/
  const firstName = useAccountStore(state => state.userAccount?.firstName);
  const lastName = useAccountStore(state => state.userAccount?.lastName);
  const email = useAccountStore(state => state.userAccount?.email);

  /******************************************************************************************
   * * GET STORE UPDATE FUNCTION
   * ---------------------------------------------------------------------------------------
   * ***************************************************************************************/
  const updateAccountState = useAccountStore(state => state.updateAccountState);

  /******************************************************************************************
   * * HANDLE ACCOUNT UPDATE
   * ---------------------------------------------------------------------------------------
   * This function handles the update of the user account in the local state
   * ***************************************************************************************/
  const handleAccountUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    updateAccountState(state => {
      if (state.userAccount && typeof value === 'string') {
        switch (name) {
          case 'firstName':
          case 'lastName':
            // Explicitly update known string properties
            state.userAccount[name] = value;
            break;
          // Handle other cases as needed
          default:
            // Optionally handle or log an error for unknown keys
            console.warn(`Attempted to update unknown UserData property: ${name}`);
        }
      }
    });
  };

  /******************************************************************************************
   * * HANDLE MUTATE ACCOUNT
   * ---------------------------------------------------------------------------------------
   * This will update the user account in the database
   * ***************************************************************************************/
  const handleMutateAccount = async () => {    
    setLoading(true);
    await updateUserName();
    setLoading(false);
  };

  return (
    <>
      <Accordian title="Personal Details" expanded={true} variant="h4">
        <Grid container spacing={2}>          
          <Grid size={{ xs: 12, md: 4 }}>
            <TextField
              name="firstName"
              label="First Name"
              value={firstName}
              onChange={handleAccountUpdate}
              fullWidth              
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <TextField
              name="lastName"
              label="Last Name"
              value={lastName}
              onChange={handleAccountUpdate}
              fullWidth              
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <TextField name="email" label="Email" value={email} disabled fullWidth />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>            
              <Button
              variant="contained"
              color="primary"
              size="medium"
              sx={{
                px: ['1.47rem'],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1, // Adjust the gap as needed for visual appearance
              }}
              onClick={handleMutateAccount}
              disabled={loading}
            >
              {loading ? (
                <>
                  <CircularProgress
                    size={24}
                    color="inherit" // This makes the progress indicator use the current text color
                  />
                  <Typography variant="body2">Updating...</Typography>
                </>
              ) : (
                <Typography variant="body2">Update</Typography>
              )}
            </Button>  
            </Box>
          </Grid>
        </Grid>
      </Accordian>
    </>
  );
};

export default UserInformation;
